export default {
  title: 'Documentation',
  section_1: {
    title: 'FDES mères',
    documents: [
      {
        img: 'doc1.png',
        url: ''
      },
      {
        img: 'doc2.png',
        url: ''
      },
      {
        img: 'doc3.png',
        url: ''
      },
    ],
  },
  section_2: {
    title: 'Catalogue des produits Meiser',
    link: {
      text: 'Catalogue des produits',
      url: '',
    },
  },
};
